<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import UploadDocumentListMixin from '../mixins/UploadDocumentListMixin'

import UploadDocumentEdit from '../../upload-document/UploadDocumentEdit'
import { TYPES as ORG_YARD_UPLOAD_DOCUMENT_TYPES } from './store'
import OrganizationYardUploadDocumentTable from './OrganizationYardUploadDocumentTable'
import IconActiveFilters from '@/components/thux-list/icon-active-filters/IconActiveFilters'
import OrganizationYardUploadDocumentCommonFilters from './OrganizationYardUploadDocumentCommonFilters'

export default {
  name: 'OrganizationYardUploadDocumentList',
  props: { orgName: {}, orgType: {}, showData: {} },
  mixins: [UploadDocumentListMixin],
  components: {
    'common-filters': OrganizationYardUploadDocumentCommonFilters,
    'component-table': OrganizationYardUploadDocumentTable,
    'component-edit': UploadDocumentEdit,
    'icon-active-filters': IconActiveFilters
  },
  watch: {
    showData () {
      if (this.showData === 2) {
        if (!this.list) {
          this.init()
        }
      }
    }
  },
  mounted () { },
  data () {
    return {
      organizationName: null,
      title: this.$t('Documents'),
      rolePerm: ['document_uploaddocument_list'],
      actionEnablePermission: ['document_uploaddocument_enable'],
      actionDisablePermission: ['document_uploaddocument_disable'],
      docFilters: { exclude__document_type__is_organization_document: 'True' }
    }
  },
  computed: {
    ...mapGetters({
      ...ORG_YARD_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationotherdocument.LIST.GETTERS
    })
  },
  methods: {
    ...mapMutations({
      setOrderBy: ORG_YARD_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationotherdocument.LIST.MUTATIONS.setOrderBy,
      setSelectedList: ORG_YARD_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationotherdocument.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ORG_YARD_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationotherdocument.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ORG_YARD_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationotherdocument.LIST.ACTIONS
    })
  }
}
</script>
