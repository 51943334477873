<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as DOCUMENT_TYPES } from '../../document/store'
import { TYPES as ORG_UPLOAD_DOCUMENT_TYPES } from './store'
import { TYPES as DOCUMENT_YARD_LINK_TYPES } from '../../document-yard-link/store'

import UploadDocumentTableMixin from '../mixins/UploadDocumentTableMixin'
import { DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS } from '@/const'

export default {
  name: 'OrganizationUploadDocumentTable',
  mixins: [UploadDocumentTableMixin],
  props: {
    yardDetail: {}
  },
  data () {
    const fields = [
      {
        key: 'status_name',
        label: this.$t('Status'),
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
        custom: true
      },
      {
        key: 'is_optional',
        label: this.$t('Optional'),
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
        sortable: true,
        custom: true
      },
      {
        key: 'document__name',
        label: this.$t('Name'),
        type: 'text',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap',
        sortable: true,
        value: 'document_name'
      },
      {
        key: 'organization__name',
        label: this.$t('Organization'),
        type: 'text',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap',
        sortable: true,
        value: 'organization_name'
      },
      {
        key: 'date_start',
        label: this.$t('Validity start'),
        type: 'text',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap',
        sortable: true,
        custom: true
      },
      {
        key: 'date_end',
        label: this.$t('Validity end'),
        type: 'text',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap',
        sortable: true,
        custom: true
      },
      {
        key: 'note',
        label: this.$t('Note'),
        type: 'text',
        thClass: 'text-nowrap',
        tdClass: 'text-wrap',
        sortable: false,
        value: 'note'
      },
      {
        key: 'action',
        label: this.$t('Actions'),
        tdClass: 'text-right text-nowrap',
        thClass: 'text-right text-nowrap'
      }
    ]
    return {
      modalItem: null,
      showUploadDocumentModal: false,
      showUploadDocumentHistoryModal: false,
      showUploadDocumentYardModal: false,
      orgId: this.$route.params.orgId,
      cntId: this.$route.params.cntId,
      fields: fields,
      fieldsColumn: ['status', 'status_name', 'document_name', 'organization__name', 'date_start', 'date_end'],
      showPopoverDocStatusModal: false,
      instance: undefined,
      DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS,
      docToDuplicate: undefined,
      showWarningModal: false,
      warningModalMsg: this.$t('The selected document already has a linked file. By continuing with this operation, the existing document will be indicated as outdated and a new version will be created to which a new file will be attached.')
    }
  },
  computed: {
    ...mapGetters({
      selectAll: ORG_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationuploaddocument.LIST.GETTERS.selectAll,
      detail: ORG_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationuploaddocument.DETAIL.GETTERS.update,
      selectedList: ORG_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationuploaddocument.LIST.GETTERS.selectedList
    })
  },
  methods: {
    ...mapMutations({
      setList: ORG_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationuploaddocument.LIST.MUTATIONS.setList,
      setSelectedList: ORG_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationuploaddocument.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ORG_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationuploaddocument.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ORG_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationuploaddocument.LIST.ACTIONS,
      update: ORG_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationuploaddocument.DETAIL.ACTIONS.update,
      delete: ORG_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationuploaddocument.DETAIL.ACTIONS.delete,
      partialUpdate: ORG_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationuploaddocument.DETAIL.ACTIONS.partialUpdate,
      duplicate: ORG_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationuploaddocument.DETAIL.ACTIONS.duplicate,
      printDocumentPdf: DOCUMENT_YARD_LINK_TYPES.GENERIC.document.documentyardlink.DETAIL.ACTIONS.download,
      downloadModel: DOCUMENT_TYPES.GENERIC.document.document.DETAIL.ACTIONS.download
    }),
    duplicateDocument () {
      this.duplicate({ id: this.docToDuplicate, yardId: this.yardId }).then(
        (item) => {
          this.$emit('open-edit-form', { id: item.id })
        }
      )
      this.closeWarningModal()
    }
  }
}
</script>
