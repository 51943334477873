<template>
  <div class="wrapper pr-0 pl-0">
    <portal-target
      v-show="showList"
      name="layout-main"
      class="layout-main panel-scroll-full"
    >
      <div class="panel-list panel-fixed panel-with-actions panel-list-no-scroll">
        <component-edit
          v-if="showEditForm && editComponent"
          :yard="$route.params.yardId"
          :organization="$route.params.orgId"
          :contact="$route.params.cntId"
          :editParams="editParams"
          :id="componentId"
          :doc-filters="docFilters"
          @on-submit='refreshListAfterSubmit'
          @close-form='closeEditForm'
        />

        <advanced-search
          v-show="showAdvancedSearch"
          v-if="toggleAdvancedSearch"
          :fields="searchFields"
          :reset="reset"
          @search="addFiltersToInstance"
          @reset="removeFiltersToInstance"
          @active="activeFilters = $event"
        />

        <panel :title="title" :no-button="true">
          <thux-list-component
            :fields="getFields"
            :pagination="getPaginationFields"
            @select-per-page="changePerPage"
            @change-page="changePage"
            :action-select="getActionSelectFields"
            @select-action="setAction"
            @select-or-deselect-all-queryset="selectOrDeselectAllQueryset"
            @do-action="doAction($event, fieldName)"
            :advanced-search="getAdvancedSearchFields"
            @show-advanced-search="openAdvancedSearch"
            @open-edit-form="openEditForm()"
            @open-detail-form="openDetailForm()"
          >
            <template slot="body">
              <component-table
                :list="list.results"
                :goBack="goBack"
                :params="tableParams"
                @open-edit-form="openEditForm"
                @open-detail-form="openDetailForm"
                @refresh-list="refreshListAfterSubmit"
              />
            </template>
          </thux-list-component>
        </panel>
      </div>
    </portal-target>
    <router-view />
    <portal-target
      v-if="showCommonFilters && showList"
      name="layout-sidebar"
      class="layout-side panel-scroll-full"
    >
      <button
        @click.prevent="closeRightSidebar"
        class="btn btn-primary text-uppercase w-100 mb-4"
        :aria-label="$t('Close')"
      >
        <i class="fas fa-times" />
        {{ $t('Hide sidebar') }}
      </button>
      <common-filters :yard="$route.params.yardId" :cntId="$route.params.cntId" :organization="this.$route.params.orgId"/>
    </portal-target>
  </div>
</template>

<script>

import ThuxListMixin from '@/components/thux-list/ThuxListMixin'
import { TYPES as UPLOAD_DOCUMENT_TYPES } from '../../upload-document/store'
import { hasPerm } from '@/components/PermissionHelper'

import UploadDocumentsTable from '../../upload-document/UploadDocumentsTable'
import UploadDocumentEdit from '../../upload-document/UploadDocumentEdit'
import UploadDocumentCommonFilters from '../../upload-document/UploadDocumentCommonFilters'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UploadDocumentListMixin',
  props: { orgName: {}, orgType: {}, showData: {} },
  mixins: [ThuxListMixin],
  components: {
    'common-filters': UploadDocumentCommonFilters,
    'component-table': UploadDocumentsTable,
    'component-edit': UploadDocumentEdit
  },
  watch: {
    showData () {
      if (this.showData === 2) {
        this.searchFields.push({
          key: 'filter__yards__code__icontains', placeholder: this.$t('Yard code'), type: 'text', col: 6
        })
      }
      if (this.showData === 1) {
        this.searchFields = this.searchFields.filter(function (obj) {
          return obj.key !== 'filter__yards__code__icontains'
        })
      }
    }
  },
  mounted () { },
  data () {
    const optionalChoices = [{
      value: 'True',
      text: this.$t('Optional')
    },
    {
      value: 'False',
      text: this.$t('Not Optional')
    }]
    const searchFields = [
      { key: 'filter__is_optional', placeholder: this.$t('Is Optional'), type: 'select', col: 6, options: optionalChoices },
      { key: 'filter__document__name__icontains', placeholder: this.$t('Document name'), type: 'text', col: 6 },
      { key: 'filter__organization__name__icontains', placeholder: this.$t('Organization name'), type: 'text', col: 6 },
      { key: 'filter__date_start__gte', placeholder: this.$t('Validity start greater than equal'), type: 'date', col: 6 },
      { key: 'filter__date_end__lte', placeholder: this.$t('Validity end less than equal'), type: 'date', col: 6 }
    ]
    return {
      organizationName: null,
      contactName: '',
      title: this.$t('Documents'),
      breadcrumbs: [],
      searchFields: searchFields,
      showCommonFilters: false
    }
  },

  computed: {
    ...mapGetters({
      documentStatusChoices: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.GETTERS.documentStatusChoices
    }),
    showList () {
      return true
    },
    canSeeActionSelect () {
      return false
    },
    canAddInstance () {
      return hasPerm('document_uploaddocument_create')
    }
  },
  methods: {
    ...mapActions({
      getDocumentstatuschoices: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.ACTIONS.getDocumentstatuschoices
    }),
    getComponentList () {
      this.removeFiltersToInstance()
      this.getDocumentstatuschoices().then(
        () => {
          const filterVals = []
          this.documentStatusChoices.forEach(el => {
            filterVals.push({
              value: el[0],
              text: el[1]
            })
          })
          this.searchFields.unshift({
            key: 'filter__status', placeholder: this.$t('Status'), type: 'select', col: 6, options: filterVals

          })
        }
      )
    },
    refreshListAfterSubmit () {
      this.removeFiltersToInstance()
      this.closeEditForm()
    },
    closeEditForm () {
      this.$set(this, 'showDetailForm', false)
      this.$set(this, 'showEditForm', false)
    },
    removeFiltersToInstance () {
      const filters = { }
      filters.filter__organization__id = this.$route.params.orgId
      filters.filter__is_passed = 'False'
      filters.filter__worker__isnull = 'True'
      this.setOrderBy('document__name')
      this.setFilters(filters).then(() => {
        try {
          this.$set(this, 'organizationName', this.list.results[0].organization_name)
        } catch {
          this.$set(this, 'organizationName', this.orgName)
        }
      })
    },
    openEditForm (item) {
      this.$set(this, 'showEditForm', false)
      this.$set(this, 'showDetailForm', false)
      this.$set(this, 'componentId', undefined)
      this.$set(this, 'editParams', {})
      if (item) {
        this.$set(this, 'componentId', item.id)
        this.$set(this, 'editParams', { onlyData: item.onlyData })
      }

      this.$set(this, 'showEditForm', true)
      this.$set(this, 'showDetailForm', false)
    }
  }
}
</script>
