<template>
  <div class="wrapper">
    <portal-target
      v-show="showList"
      name="layout-main"
      class="layout-main panel-scroll-full"
    >
      <div class="panel-list panel-fixed panel-with-actions">
        <div class="panel-header">
          <h1 class="page-header mr-2 d-flex align-items-center">
            {{ $t("Organization's documents") }}{{ orgDetail.name ? `, ${orgDetail.name}` : '' }} {{orgType}}
          <div class="page-header-actions">
            <b-btn
              variant="outline-primary"
              v-has-perms="DownloadOrgDocumentsRolePerm"
              :title="'Download organization documents' | translate"
              @click="openDownloadDocumentsModal()"
            >
              <i class="fas fa-cloud-download-alt"></i>
            </b-btn>
          </div>
            </h1>
          <ol class="breadcrumb pull-right">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'dashboard'}">
                {{"Dashboard"}}
              </router-link>
            </li>
            <li v-for="(breadcrumb) in breadcrumbs" :key="breadcrumb.label" class="breadcrumb-item">
              <router-link :to="breadcrumb.route">
                {{ breadcrumb.label | translate }}
              </router-link>
            </li>
            <li class="breadcrumb-item">{{ title | translate }}</li>
          </ol>
        </div>
        <b-tabs nav-class="nav-tabs-inverse" nav-wrapper-class="nav nav-tabs nav-tabs-inverse">
          <b-tab @click="setSelectedTab(1)">
            <template slot="title">
              <span class="d-sm-block d-none">{{'Organization documents' | translate }}</span>
            </template>
            <org-doc-list :showData="selectedTab"></org-doc-list>
          </b-tab>
            <!-- end tab -->
            <!-- begin tab -->
          <b-tab @click="setSelectedTab(2)">
            <template slot="title">
              <span class="d-sm-block d-none">{{'Other documents' | translate }}</span>
            </template>
            <org-yard-doc-list :showData="selectedTab"></org-yard-doc-list>
          </b-tab>
        </b-tabs>
      </div>
    </portal-target>
    <router-view />
    <download-documents-modal
      v-if="showDownloadDocumentsModal"
      :orgId="orgId"
      :orgName="orgDetail.name"
      @close-modal="closeDownloadDocumentsModal()"
      @download-company-registry="downloadRegistry($event)"
    />
  </div>
</template>

<script>
import { ROUTES as YARD_ROUTES } from '../../yard/yard/router'
import { YARD_WORK_STATUSES, ORGANIZATION_CUSTOMER_CODE, ORGANIZATION_SUPPLIER_CODE, ORGANIZATION_CUSTOMER_LEAD_CODE } from '@/const'
import { hasPerm } from '@/components/PermissionHelper'

import OrganizationUploadDocumentList from './organization-document/OrganizationUploadDocumentList'
import OrganizationYardUploadDocumentList from './other-document/OrganizationYardUploadDocumentList'
import DownloadDocumentsModal from '../../organization/components/organization/organization-modal/DownloadDocumentsModal.vue'
import { ROUTES as CUSTOMER_ORG_ROUTES } from '../../organization/organization/organization/customer-organization/router'
import { ROUTES as OWNER_ORG_ROUTES } from '../../organization/organization/organization/owner-organization/router'
import { ROUTES as LEAD_ORG_ROUTES } from '../../organization/organization/organization/lead-organization/router'
import { ROUTES as SUPPLIER_ORG_ROUTES } from '../../organization/organization/organization/supplier-organization/router'
import { TYPES as ORG_TYPES } from '../../organization/organization/store'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'OrganizationuploadDocumentPage',
  props: { orgName: {}, orgType: {}, cntName: {} },
  mixins: [],
  components: {
    'org-doc-list': OrganizationUploadDocumentList,
    'org-yard-doc-list': OrganizationYardUploadDocumentList,
    DownloadDocumentsModal
  },
  created () {
    this.retrieveOrg({ id: this.$route.params.orgId })
    this.setBreadcrumbs()
  },
  data () {
    return {
      YARD_ROUTES,
      SUPPLIER_ORG_ROUTES,
      organizationName: null,
      contactName: '',
      YARD_WORK_STATUSES,
      title: this.$t('Documents'),
      breadcrumbs: [],
      selectedTab: 1,
      showDownloadDocumentsModal: false,
      orgId: this.$route.params.orgId,
      DownloadOrgDocumentsRolePerm: 'document_uploaddocument_generate_documents_zip_job'
    }
  },
  computed: {
    showList () {
      return true
    },
    canSeeActionSelect () {
      return false
    },
    canAddInstance () {
      return hasPerm('document_uploaddocument_create')
    },
    ...mapGetters({
      orgDetail: ORG_TYPES.GENERIC.organization.organization.DETAIL.GETTERS.detail
    })
  },
  methods: {
    ...mapActions({
      retrieveOrg: ORG_TYPES.GENERIC.organization.organization.DETAIL.ACTIONS.retrieve
    }),
    setSelectedTab (tabNum) {
      this.$set(this, 'selectedTab', tabNum)
    },
    setBreadcrumbs () {
      if (this.orgType === ORGANIZATION_SUPPLIER_CODE) {
        this.$set(this, 'breadcrumbs', [
          {
            label: 'Organizations',
            route: {
              name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_LIST,
              // : CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_LIST,
              params: { orgType: ORGANIZATION_SUPPLIER_CODE }
            }
          },
          {
            label: 'Organization',
            route: {
              name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_DETAIL,
              params: {
                orgType: ORGANIZATION_SUPPLIER_CODE,
                id: this.$route.params.orgId
              }
            }
          }
        ])
      } else if (this.orgType === ORGANIZATION_CUSTOMER_LEAD_CODE) {
        this.$set(this, 'breadcrumbs', [
          {
            label: 'Organizations',
            route: {
              name: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_LIST,
              params: { orgType: ORGANIZATION_CUSTOMER_LEAD_CODE }
            }
          },
          {
            label: 'Organization',
            route: {
              name: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_DETAIL,
              params: {
                orgType: ORGANIZATION_CUSTOMER_LEAD_CODE,
                id: this.$route.params.orgId
              }
            }
          }
        ])
      } else if (this.orgType === ORGANIZATION_CUSTOMER_CODE) {
        this.$set(this, 'breadcrumbs', [
          {
            label: 'Organizations',
            route: {
              name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_LIST,
              params: { orgType: ORGANIZATION_CUSTOMER_CODE }
            }
          },
          {
            label: 'Organization',
            route: {
              name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DETAIL,
              params: {
                orgType: ORGANIZATION_CUSTOMER_CODE,
                id: this.$route.params.orgId
              }
            }
          }
        ])
      } else {
        this.$set(this, 'breadcrumbs', [
          {
            label: 'Organization',
            route: {
              name: OWNER_ORG_ROUTES.OWNER_ORGANIZATION_DETAIL,
              params: {
                id: this.$route.params.orgId
              }
            }
          }
        ])
      }
    },
    openDownloadDocumentsModal () {
      this.$set(this, 'showDownloadDocumentsModal', true)
    },
    closeDownloadDocumentsModal () {
      this.$set(this, 'showDownloadDocumentsModal', false)
    }
  }
}
</script>
