<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import UploadDocumentListMixin from '../mixins/UploadDocumentListMixin'

import UploadDocumentEdit from '../../upload-document/UploadDocumentEdit'
import { TYPES as ORG_UPLOAD_DOCUMENT_TYPES } from './store'
import OrganizationUploadDocumentTable from './OrganizationUploadDocumentTable'
import IconActiveFilters from '@/components/thux-list/icon-active-filters/IconActiveFilters'
import OrganizationUploadDocumentCommonFilters from './OrganizationUploadDocumentCommonFilters'

export default {
  name: 'OrganizationUploadDocumentList',
  props: { orgName: {}, orgType: {}, showData: {} },
  mixins: [UploadDocumentListMixin],
  components: {
    'common-filters': OrganizationUploadDocumentCommonFilters,
    'component-table': OrganizationUploadDocumentTable,
    'component-edit': UploadDocumentEdit,
    'icon-active-filters': IconActiveFilters
  },
  watch: {
    showData () {
      if (this.showData === 1) {
        if (!this.list) {
          this.init()
        }
      }
    }
  },
  mounted () { },
  data () {
    return {
      organizationName: null,
      title: this.$t('Documents'),
      rolePerm: ['document_uploaddocument_list'],
      actionEnablePermission: ['document_uploaddocument_enable'],
      actionDisablePermission: ['document_uploaddocument_disable'],
      docFilters: { filter__document_type__is_organization_document: 'True' }
    }
  },
  computed: {
    ...mapGetters({
      ...ORG_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationuploaddocument.LIST.GETTERS
    })
  },
  methods: {
    ...mapMutations({
      setOrderBy: ORG_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationuploaddocument.LIST.MUTATIONS.setOrderBy,
      setSelectedList: ORG_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationuploaddocument.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ORG_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationuploaddocument.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ORG_UPLOAD_DOCUMENT_TYPES.GENERIC.document.organizationuploaddocument.LIST.ACTIONS
    })
  }
}
</script>
