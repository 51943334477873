<template>
  <!-- Add/Edit Form -->
  <b-modal
    id="modalDownloadOrganizationDocuments"
    ref="modalDownloadOrganizationDocuments"
    :no-enforce-focus="true"
    :no-close-on-backdrop = 'true'
    size='lg'
    @hidden="modalDownloadOrganizationDocumentsOnHidden"
  >
    <div slot="modal-title">
      {{ modalTitle | translate }}
    </div>
    <div class="mb-3">
      <p>
        {{ 'Select the type of documents you want to download.' | translate }}
      </p>
      <p>
        {{ 'Once the download has been requested, the system will generate a .zip file containing the required documents.' | translate }}
      </p>
      <p>
        {{ 'When the required file is ready, the download link will be displayed in the in bar by pressing the icon' | translate }} <i class="fas fa-cloud-download-alt"></i>
      </p>
    </div>
    <b-form>
      <b-form-row>
        <form-thux-horizontal-checkbox
          :validator="$v.printForm.organization_docs"
          :label-form="'Organization documents' | translate"
          class-form="col-lg-6 mb-2"
          label-cols-lg="6"
          label-cols-sm="6"
          :value="printForm.organization_docs"
          @change="$set(printForm, 'organization_docs', $event)"
        />
        <!-- <form-thux-horizontal-checkbox
          :validator="$v.printForm.nominations"
          :label-form="'Nominations' | translate"
          class-form="col-lg-6 mb-2"
          label-cols-lg="6"
          label-cols-sm="6"
          :value="printForm.nominations"
          @change="$set(printForm, 'nominations', $event)"
        /> -->
        <form-thux-horizontal-checkbox
          :validator="$v.printForm.worker"
          :label-form="'Worker documents' | translate"
          class-form="col-lg-6 mb-2"
          label-cols-lg="6"
          label-cols-sm="6"
          :value="printForm.worker"
          @change="selectWorkerDocuments($event, 'worker')"
        />
        <form-thux-horizontal-checkbox
          :validator="$v.printForm.worker_training"
          :label-form="'Worker training' | translate"
          class-form="col-lg-6 mb-2"
          label-cols-lg="6"
          label-cols-sm="6"
          :value="printForm.worker_training"
          @change="selectWorkerDocuments($event, 'worker_training')"
        />
      </b-form-row>
    </b-form>

    <div v-if="showStaffList" style="border: 1px solid; padding: 15px;">

      <div class="mb-3">
        {{message}}
      </div>

      <thux-list-component
        :fields="getFields"
        :pagination="getPaginationFields"
        @select-per-page="changePerPage"
        @change-page="changePage"
        :action-select="getActionSelectFields"
        @select-action="setAction"
        @select-or-deselect-all-queryset="selectOrDeselectAllQueryset"
        @do-action="doAction($event, fieldName)"
        :advanced-search="getAdvancedSearchFields"
        @show-advanced-search="openAdvancedSearch"
      >
        <template slot="body">
          <component-table
            :list="list.results"
            :goBack="goBack"
            :params="tableParams"
          />
        </template>
      </thux-list-component>
    </div>
    <div slot="modal-footer">
      <b-btn class="mr-3" variant="default" @click.prevent="closeAndGoToList()">
        {{ 'Cancel' | translate }}
      </b-btn>
      <b-btn
        variant="primary"
        @click.prevent="closeAndDownloadDocument()"
      >
        {{ 'OK' | translate }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import slugify from 'slugify'

import FormThuxHorizontalCheckbox from '@/components/form-thux-horizontal-components/FormThuxHorizontalCheckbox'
import ThuxListMixin from '@/components/thux-list/ThuxListMixin'
import DownloadDocumentsModalContactTable from './DownloadDocumentsModalContactTable'

import { TYPES as ORG_CNT_TYPES } from '../../../organization-contact/store'
import { TYPES as UPLOAD_DOCUMENT_TYPES } from '../../../../document/upload-document/store'

export default {
  name: 'CompanyRegistryModal',
  mixins: [ThuxListMixin],
  props: ['orgId', 'orgName'],
  data () {
    return {
      selectedItemId: undefined,
      showCommonFilters: false,
      showStaffList: false,
      printForm: {}
    }
  },
  components: {
    'form-thux-horizontal-checkbox': FormThuxHorizontalCheckbox,
    'component-table': DownloadDocumentsModalContactTable
  },
  created () { },
  mounted () {
    this.openModalDownloadOrganizationDocuments()
  },
  computed: {
    ...mapGetters({
      ...ORG_CNT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS,
      selectedList: ORG_CNT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS.selectedList
    }),
    modalTitle () {
      return this.$t('Select documents to download')
    },
    message () {
      return this.$t('Select the personnel whose required documents you want to download. If no name is selected, the documents of all staff will be downloaded.')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: ORG_CNT_TYPES.GENERIC.organization.organizationcontact.LIST.MUTATIONS.setSelectedList
    }),
    ...mapActions({
      ...ORG_CNT_TYPES.GENERIC.organization.organizationcontact.LIST.ACTIONS,
      generateDocumentsZipJob: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.ACTIONS.generateDocumentsZipJob
    }),
    openModalDownloadOrganizationDocuments () {
      this.$refs.modalDownloadOrganizationDocuments.show()
    },
    modalDownloadOrganizationDocumentsOnHidden (evt) {
      this.$emit('close-modal')
    },
    closeAndGoToList (evt) {
      this.$emit('close-modal')
    },
    getComponentList () { },
    closeAndDownloadDocument () {
      const formData = {}
      formData.file_name = `${slugify(this.orgName)}.zip`
      formData.organization_id = this.orgId
      if (this.printForm.organization_docs) {
        formData.is_organization_document = this.printForm.organization_docs
      }
      if (this.printForm.nominations) {
        formData.is_nomination_document = this.printForm.nominations
      }
      if (this.printForm.worker_training || this.printForm.worker) {
        if (this.printForm.worker_training) {
          formData.training = this.printForm.worker_training
        }
        if (this.printForm.worker) {
          formData.worker_documents = this.printForm.worker
        }
        const selectedIdList = this.selectedList.map(a => a.contact)
        if (selectedIdList) {
          formData.workers = selectedIdList
        }
      }
      this.generateDocumentsZipJob(formData).then(() => {
        setTimeout(() => {
          this.closeAndGoToList()
        }, 500)
      })
    },
    selectWorkerDocuments (evt, key) {
      this.$set(this.printForm, key, evt)
      if (evt) {
        if (!this.showStaffList) {
          this.$set(this, 'showStaffList', true)
          const filters = {
            filter__organization__id: this.orgId,
            filter__status: 1,
            filter__contact__status: 1
          }
          this.setFilters(filters)
        }
      } else {
        if (!this.printForm.worker && !this.printForm.worker_training) {
          this.$set(this, 'showStaffList', false)
          this.setSelectedList([])
        }
      }
    }
  },
  validations: {
    printForm: {
      organization_docs: {},
      nominations: {},
      worker: {},
      worker_training: {}
    }
  }
}
</script>
