<template>
  <div>
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :fields-column="fieldsColumn"
      :select-all="selectAll"
      :selected-list="selectedList"
      :get-local-selected-all="getLocalSelectedAll"
      :local-selected-list="localSelectedList"
      @check-all="checkAll"
      @check-none="checkNone"
      @set-selected-row="setSelectedRow"
      :show-checks="showChecks"
      :showEditButton="showEditButton"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
    >
      <template slot="is_optional" slot-scope="row">
        <status-indicator :status="row.item.is_optional" />
      </template>
      <template slot="status_name" slot-scope="row">
        <div class="mt-3 f-w-600 d-flex justify-content-start">
        <document-status-popover
          v-if="row.item.attachment"
          :open-popover="
            instance &&
            row.item.id === instance.id &&
            showPopoverDocStatusModal"
          :field-name="$t('Status')"
          :id="row.item.id"
          :instance="row.item"
          :there-are-fields="row.item.status"
          :doc-status="row.item.status"
          :doc-status-name="row.item.status_name"
          :doc-validation-note="row.item.validation_note"
          :btn-title="$t('Change status')"
          @show-popover-modal="showPopoverDocumentStatus(row.item)"
          @close="reset()"
          @change-status="changeUploadDocumentStatus($event)"
        />
        <span v-else  :class="`fw-600 tag tag-${DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS[row.item.status]}`">
          {{row.item.status_name}}
        </span>
        <span v-if="row.item.validation_note && row.item.status === 'P'">
          <v-popover
            offset="16"
            trigger="hover"
            placement="top"
            :auto-hide="true"
          >
            <slot name="body">
              <i class="fas fa-comment"></i>
            </slot>
            <template slot="popover">
                {{row.item.validation_note}}
            </template>
          </v-popover>
          <!-- <i class="fas fa-comment" :title="row.item.validation_note"></i> -->
          </span>
        </div>
      </template>

      <template slot="date_start" slot-scope="row">
          {{ row.item.date_start | date}}
      </template>

      <template slot="date_end" slot-scope="row">
          <span :class="row.item.is_document_expired ? 'text-red' : ''">
            {{ row.item.date_end | date}}
          </span>
      </template>

      <template slot="yards" slot-scope="row">
        {{row.item.yard_name_short_list}}
      </template>

      <template slot="other-buttons-actions-bottom" slot-scope="row">
        <b-btn v-if="row.item.is_downloadable || row.item.is_created_by_template"
          variant="outline-primary"
          v-has-perms="downloadModelRolePerm"
          :title="'Download model' | translate"
          @click="generateDocument(row.item)"
        >
          <i class="fas fa-file-download"></i>
        </b-btn>
        <b-btn v-if="!row.item.attachment && !row.item.is_dpi_document"
          variant="outline-primary"
          v-has-perms="uploadCopyRolePerm"
          :title="'Upload copy' | translate"
          :disabled="(yardDetail && yardDetail.is_active === false)"
          @click.prevent="$emit('open-edit-form', {id: row.item.id})"
        >
          <i class="fas fa-upload"></i>
        </b-btn>
        <b-btn v-if="row.item.attachment && !row.item.is_dpi_document"
          variant="outline-primary"
          v-has-perms="uploadCopyRolePerm"
          :title="'Upload copy' | translate"
          :disabled="(yardDetail && yardDetail.is_active === false)"
          @click.prevent="updateDocumentRequest(row.item)"
        >
          <i class="fas fa-upload"></i>
        </b-btn>
        <b-btn v-if="row.item.attachment"
          variant="outline-primary"
          v-has-perms="downloadDocRolePerm"
          :title="'download' | translate"
          @click="downloadDocument(row.item)"
        >
          <i class="fas fa-download"></i>
        </b-btn>
        <b-btn
          v-if='orgId && !cntId'
          variant="outline-primary"
          v-has-perms="showYardsRolePerm"
          :title="'Show yards' | translate"
          @click="showYardModal(row.item)"
        >
          <i class="fas fa-building"></i>
        </b-btn>
        <b-btn
          variant="outline-primary"
          v-has-perms="showHistoryRolePerm"
          :title="'Show history' | translate"
          @click="showHistoryModal(row.item)"
        >
          <i class="fas fa-history"></i>
        </b-btn>
        <b-btn
          variant="outline-primary"
          v-has-perms="editRolePerm"
          :title="'Edit data' | translate"
          @click="showEditDataForm(row.item)"
        >
          <i class="fas fa-edit"></i>
        </b-btn>
        <b-btn
          v-if="!me.profiles.includes('backoffice')"
          variant="outline-primary"
          v-has-perms="deleteRolePerm"
          :title="'Delete' | translate"
          @click="openDeleteModal(row.item)"
        >
          <i class="far fa-trash-alt"></i>
        </b-btn>
      </template>
    </thux-table>
    <upload-document-modal
      v-if="showUploadDocumentModal"
      :yard-document-id="modalItem.id"
      :doc-item="modalItem"
      :title="$t('Upload document')"
      @close="closeModal"
    />
    <document-history-modal
      v-if="showUploadDocumentHistoryModal"
      :upload-document-id="modalItem.id"
      :upload-document-name="modalItem.document_name"
      :upload-organization-name="modalItem.organization_name"
      :title="$t('Upload document')"
      @close="closeModal"
    />
    <document-yard-modal
      v-if="showUploadDocumentYardModal"
      :upload-document-id="modalItem.id"
      :upload-document-name="modalItem.document_name"
      :upload-organization-name="modalItem.organization_name"
      :title="$t('Upload document')"
      @close="closeModal"
    />
    <ConfirmDialog
      v-if="showWarningModal && docToDuplicate"
      ref-id="modal-confirm"
      :no-close-on-backdrop="true"
      content-class="attachment-modal"
      confirm-btn-variant="primary"
      :title="$t('WARNING')"
      :button-confirm-text="'Continue' | translate"
      @confirm-dialog-close="closeWarningModal"
      @confirm-dialog-select="duplicateDocument"
    >
      <template slot="body">
        {{warningModalMsg}}
      </template>
    </ConfirmDialog>
    <ConfirmDialog
      v-if="showDeleteModal && docToDelete"
      ref-id="modal-delete"
      :no-close-on-backdrop="true"
      content-class="attachment-modal"
      confirm-btn-variant="primary"
      :title="$t('WARNING')"
      :button-confirm-text="'Continue' | translate"
      @confirm-dialog-close="closeDeleteModal"
      @confirm-dialog-select="deleteDocument"
    >
      <template slot="body">
        <h4> {{$t('Deleting the document is a non-reversible operation')}}</h4>
        {{deleteModalMsg}}
      </template>
    </ConfirmDialog>
    <WarningDialog
      v-if="showWarningDialog"
      ref-id="modal-warning"
      :no-close-on-backdrop="true"
      confirm-btn-variant="primary"
      :confirm-btn-disabled="false"
      :title="$t('WARNING')"
      :body="warningDialogMessage"
      :button-confirm-text="'OK' | translate"
      @warning-dialog-close="closeWarningDialogModal"
    >
    </WarningDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { TYPES as AUTH_TYPES } from '@/pages/auth/store'
import ThuxTableMixin from '@/components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '@/components/thux-table/thux-table-checks/ThuxTableChecksMixin'
import StatusIndicator from '@/components/StatusIndicator'
import UploadDocumentHistoryModal from '../../upload-document/history/UploadDocumentHistoryModal'
import UploadDocumentYardModal from '../../upload-document/yard/UploadDocumentYardModal'
import UploadDocumentModal from '../../upload-document/UploadDocumentModal'
import { DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS } from '@/const'
import ConfirmDialog from '@/components/ConfirmDialog'
import WarningDialog from '@/components/WarningDialog'
import UploadDocumentStatusFieldPopover from '@/pages/document/components/UploadDocumentStatusFieldPopover'

export default {
  name: 'UploadDocumentTableMixin',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  props: {
    yardDetail: {}
  },
  components: {
    UploadDocumentModal,
    StatusIndicator,
    ConfirmDialog,
    WarningDialog,
    'document-status-popover': UploadDocumentStatusFieldPopover,
    'document-history-modal': UploadDocumentHistoryModal,
    'document-yard-modal': UploadDocumentYardModal
  },
  data () {
    const fields = [
      {
        key: 'status_name',
        label: this.$t('Status'),
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
        custom: true
      },
      {
        key: 'is_optional',
        label: this.$t('Optional'),
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
        sortable: true,
        custom: true
      },
      {
        key: 'document__name',
        label: this.$t('Name'),
        type: 'text',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap',
        sortable: true,
        value: 'document_name'
      },
      {
        key: 'organization__name',
        label: this.$t('Organization'),
        type: 'text',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap',
        sortable: true,
        value: 'organization_name'
      },
      {
        key: 'date_start',
        label: this.$t('Validity start'),
        type: 'text',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap',
        sortable: true,
        custom: true
      },
      {
        key: 'date_end',
        label: this.$t('Validity end'),
        type: 'text',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap',
        sortable: true,
        custom: true
      },
      {
        key: 'note',
        label: this.$t('Note'),
        type: 'text',
        thClass: 'text-nowrap',
        tdClass: 'text-wrap',
        sortable: false,
        value: 'note'
      },
      {
        key: 'action',
        label: this.$t('Actions'),
        tdClass: 'text-right text-nowrap',
        thClass: 'text-right text-nowrap'
      }
    ]
    return {
      modalItem: null,
      showUploadDocumentModal: false,
      showUploadDocumentHistoryModal: false,
      showUploadDocumentYardModal: false,
      orgId: this.$route.params.orgId,
      cntId: this.$route.params.cntId,
      fields: fields,
      fieldsColumn: ['status', 'status_name', 'document_name', 'organization__name', 'date_start', 'date_end'],
      showPopoverDocStatusModal: false,
      instance: undefined,
      DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS,
      docToDuplicate: undefined,
      showWarningModal: false,
      docToDelete: undefined,
      showDeleteModal: false,
      showWarningDialog: false,
      deleteModalMsg: this.$t(''),
      warningDialogMessage: '',
      warningModalMsg: this.$t('The selected document already has a linked file. By continuing with this operation, the existing document will be indicated as outdated and a new version will be created to which a new file will be attached.'),
      // Permissions
      downloadModelRolePerm: 'document_document_download_file',
      deleteRolePerm: 'document_uploaddocument_destroy',
      editRolePerm: 'document_uploaddocument_update',
      showHistoryRolePerm: 'document_uploaddocument_history',
      showYardsRolePerm: 'document_documentyardlink_list',
      downloadDocRolePerm: 'document_uploaddocument_download_attachment',
      uploadCopyRolePerm: 'document_uploaddocument_update'
    }
  },
  created () { },
  computed: {
    ...mapGetters({
      me: AUTH_TYPES.AUTH.me
    }),
    showChecks () {
      return false
    },
    showDetailButton () {
      return false
    },
    showEditButton () {
      return false
    },
    yardId () {
      return this.$route.params.yardId
    }
  },
  methods: {
    showHistoryModal (item) {
      this.$set(this, 'modalItem', item)
      this.$set(this, 'showUploadDocumentHistoryModal', true)
      this.$set(this, 'showUploadDocumentModal', false)
      this.$set(this, 'showUploadDocumentYardModal', false)
    },
    showYardModal (item) {
      this.$set(this, 'modalItem', item)
      this.$set(this, 'showUploadDocumentYardModal', true)
      this.$set(this, 'showUploadDocumentHistoryModal', false)
      this.$set(this, 'showUploadDocumentModal', false)
    },
    closeModal () {
      this.$set(this, 'modalItem', null)
      this.$set(this, 'showUploadDocumentModal', false)
      this.$set(this, 'showUploadDocumentHistoryModal', false)
      this.$set(this, 'showUploadDocumentYardModal', false)
    },
    removeOrderFromTable (orderBy) {
      delete this.orderByDict[orderBy]
      const orderByListString = Object.values(this.orderByDict).map(
        value => `${value.sortDesc ? '-' : ''}${value.orderBy}`
      )
      let index = 1
      Object.keys(this.orderByDict).forEach((key) => {
        this.orderByDict[key].index = index
        index += 1
      })
      if (orderByListString.length === 0) {
        orderByListString.push('document__name')
      }
      this.changeOrderBy(orderByListString.join('.'))
    },
    downloadDocument (item) {
      const fileName = `${item.document_name}.${item.attachment_simple_filename.split('.').pop()}`
      this.download({ id: item.id, action: 'download-attachment', simpleFileName: fileName })
    },
    generateDocument (item) {
      if (item.is_created_by_template) {
        const yardLink = item.valid_links.find(x => x.yard === parseInt(this.yardId))
        this.printDocumentPdf({ id: yardLink.id, action: 'print-pdf', simpleFileName: `${item.document_name}.pdf` })
      } else {
        this.downloadModel({ id: item.document, action: 'download-document', simpleFileName: item.document_simple_filename })
      }
    },
    showPopoverDocumentStatus (uploadDocument) {
      this.$set(this, 'instance', uploadDocument)
      this.$set(this, 'showPopoverDocStatusModal', true)
    },
    reset () {
      this.$set(this, 'instance', {})
      this.$set(this, 'showPopoverDocStatusModal', false)
    },
    changeUploadDocumentStatus (evt) {
      const formData = { id: this.instance.id, ...evt }
      this.partialUpdate(formData).then(
        () => {
          this.$set(this, 'instance', {})
          this.$set(this, 'showPopoverDocStatusModal', false)
        }
      )
    },
    updateDocumentRequest (uploadDocument) {
      this.$set(this, 'docToDelete', undefined)
      this.$set(this, 'showDeleteModal', false)
      this.$set(this, 'docToDuplicate', uploadDocument.id)
      this.$set(this, 'showWarningModal', true)
    },
    closeWarningModal () {
      this.$set(this, 'docToDuplicate', undefined)
      this.$set(this, 'showWarningModal', false)
    },
    duplicateDocument () {
      this.duplicate({ id: this.docToDuplicate, yardId: this.yardId }).then(
        (item) => {
          this.$emit('open-edit-form', { id: item.id })
        }
      )
      this.closeWarningModal()
    },
    showEditDataForm (uploadDocument) {
      this.$emit('open-edit-form', { id: uploadDocument.id, onlyData: true })
    },
    openDeleteModal (uploadDocument) {
      if (uploadDocument.is_passed) {
        this.$set(this, 'docToDuplicate', undefined)
        this.$set(this, 'showWarningDialog', true)
        const msg = 'Unable to delete the document. A more recent version of the selected document is present in the system.'
        this.$set(this, 'warningDialogMessage', msg)
      } else {
        this.$set(this, 'docToDuplicate', undefined)
        this.$set(this, 'showWarningModal', false)
        this.$set(this, 'docToDelete', uploadDocument.id)
        this.$set(this, 'showDeleteModal', true)
        const msg = `${this.$t('Do you want to proceed with the elimination of document')} ${uploadDocument.document_name}?`
        this.$set(this, 'deleteModalMsg', msg)
      }
    },
    closeWarningDialogModal () {
      this.$set(this, 'docToDelete', undefined)
      this.$set(this, 'showWarningDialog', false)
    },
    closeDeleteModal () {
      this.$set(this, 'docToDelete', undefined)
      this.$set(this, 'showDeleteModal', false)
    },
    deleteDocument () {
      this.delete({ id: this.docToDelete }).then(
        () => {
          this.closeDeleteModal()
        }
      )
    }
  }
}
</script>
